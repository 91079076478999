<template>
  <div>
    <h4>MARCAS</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dForm.show();
        $refs.formBrand.reset();
      "
    >
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>MARCA</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>{{ l.id }}</td>
            <td>{{ l.name }}</td>
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.dForm.show();
                    $refs.formBrand.loadReg(l);
                  "
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.dDel.show();
                    $refs.dDel.val = l.id;
                  "
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <h4>MARCA</h4>
      <FormBrand
        ref="formBrand"
        @submitted="
          $refs.tr.gl();
          $refs.dForm.hide();
        "
      ></FormBrand>
    </app-modal-basic>

    <app-modal-yn
      ref="dDel"
      @yes="deleteItem($refs.dDel.val)"
      @no="$refs.dDel.hide()"
    >
      <p>
        Eliminar el registro
        <span v-if="$refs.dDel && $refs.dDel.val">{{ $refs.dDel.val }}</span>
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
import FormBrand from "./Form";
import { GeneralService } from "../GeneralService";

export default {
  components: {
    FormBrand
  },
  data: () => ({
    list: {}
  }),
  methods: {
    deleteItem(id) {
      GeneralService.deleteBrand(id).then(() => {
        this.$refs.dDel.hide();
      });
    },
    getList(params) {
      return new Promise(rsv => {
        GeneralService.getBrands({ ...params }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style>
</style>
